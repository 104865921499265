export enum Locales {
  Sl = 'sl',
}

export enum Routes {
  Login = '/',
  Register = '/auth/create-account',
  PasswordResetRequest = '/auth/password-reset',
  Dashboard = '/dashboard',
  Inventory = '/inventory',
  InventoryPublish = '/inventory/:id/publish',
  InventoryEdit = '/inventory/:id/details',
  InventoryAdd = '/inventory/create',
  Selling = '/selling',
  PublishedItemDetail = '/selling/:id/',
  Orders = '/orders',
  OrdersHistory = '/orders/history',
  Settings = '/settings',
  SettingsCompany = '/settings/company',
  SettingsBranchOffices = '/settings/branch-offices',
  SettingsBranchOfficesCreate = '/settings/branch-offices/new',
  SettingsTeam = '/settings/team',
  SettingsProfile = '/settings/profile',
  SettingsSecurity = '/settings/security',
  Support = '/support',
  Policy = '/policy',
}

export const localizedRoutes = {
  [Routes.Login]: {
    [Locales.Sl]: '/avtentikacija/vpis',
  },
  [Routes.Register]: {
    [Locales.Sl]: '/avtentikacija/kreiraj-racun',
  },
  [Routes.Dashboard]: {
    [Locales.Sl]: '/nadzorna-plosca',
  },
  [Routes.Inventory]: {
    [Locales.Sl]: '/inventar',
  },
  [Routes.Selling]: {
    [Locales.Sl]: '/prodaja',
  },
  [Routes.Orders]: {
    [Locales.Sl]: '/narocila',
  },
  [Routes.Settings]: {
    [Locales.Sl]: '/nastavitve',
  },
}
