import axios from 'axios'
import { getCookie } from '~/services/authentication'
import { Routes } from '~/routing/routes'
import { useUserStore } from '~/stores/user.store'

export const axiosInstance = axios.create()

axiosInstance.interceptors.request.use((config) => {
  const c = useRuntimeConfig()
  config.baseURL = c.public.backendBase
  return config
})

export const axiosInstanceAuth = axios.create({
  withCredentials: true,
})
axiosInstanceAuth.interceptors.request.use((config) => {
  const c = useRuntimeConfig()
  config.baseURL = c.public.backendBase
  config.headers['x-csrftoken'] = getCookie('csrftoken')
  return config
})

axiosInstanceAuth.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const userStore = useUserStore()
    if (error.response.status === 401) {
      userStore.isLoggedIn = false
    }
    if (error.response.status === 401 && !error.response.config.url.startsWith('/api/auth/user/me')) {
      const router = useRouter()
      router.push(`${Routes.Login}?session_expired=1`)
    }
    return Promise.reject(error)
  }
)
