<template>
  <div>
    <Head>
      <Title>Krožnik.si | Rešujemo hrano</Title>
      <Meta
        name="description"
        content="Krožnik rešuje hrano pred smetmi. Povezujemo trgovce in kupce za boljši svet."
      />
      <Meta name="lang" content="sl" />
    </Head>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import { AuthApi, Configuration, type UserInfo } from './client_api'
import { useUserStore } from '~/stores/user.store'
import { axiosInstance } from './client_api/exios'

const headers = useRequestHeaders()
const { data, error: badUserError } = await useFetch('/api/user', { headers })

const userStore = useUserStore()
const router = useRouter()
const route = useRoute()

if (badUserError.value !== null) {
  if (!(route.path === '/' || route.path.startsWith('/auth'))) {
    router.replace('/')
  }
} else {
  router.replace(route.path)
}

useSeoMeta({
  ogTitle: 'Krožnik.si | Rešujemo hrano',
  ogDescription: 'Krožnik rešuje hrano pred smetmi. Povezujemo trgovce in kupce za boljši svet.',
  ogImage: '[og:image]',
  ogUrl: 'https://portal.kroznik.si',
  twitterTitle: 'Krožnik.si | Rešujemo hrano',
  twitterDescription:
    'Krožnik rešuje hrano pred smetmi. Povezujemo trgovce in kupce za boljši svet.',
  twitterImage: '[twitter:image]',
  twitterCard: 'summary',
})

onMounted(() => {
  if (badUserError.value === null) {
    userStore.setIsLoggedIn(true)
    userStore.setUser(data.value as UserInfo)
  } else {
    userStore.setIsLoggedIn(false)
  }
  new AuthApi(new Configuration(), '', axiosInstance).authCsrfRetrieve({ withCredentials: true })
})
</script>
