const TOKEN_NAME = 'accessToken'

export const saveAccessToken = (token: string) => {
  localStorage.setItem(TOKEN_NAME, token)
}

export const getAccessToken = (): string | null => {
  return localStorage.getItem(TOKEN_NAME)
}

export const removeAccessToken = () => {
  localStorage.removeItem(TOKEN_NAME)
}

export const getCookie = (name: string): string | null => {
  const cookieArr = document.cookie.split(';')

  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split('=')

    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1])
    }
  }

  return null
}
