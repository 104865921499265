import { default as create_45accountVBTzotrxSxMeta } from "/code/pages/auth/create-account.vue?macro=true";
import { default as email_45confirmation_45successHt59hw76MWMeta } from "/code/pages/auth/email-confirmation-success.vue?macro=true";
import { default as _91token_93UigJ9VlsKBMeta } from "/code/pages/auth/password-confirm/[uid]/[token].vue?macro=true";
import { default as password_45resetG8ND7qxmiNMeta } from "/code/pages/auth/password-reset.vue?macro=true";
import { default as index8Mn5LmJV3VMeta } from "/code/pages/dashboard/index.vue?macro=true";
import { default as indexKRDw4UYEiPMeta } from "/code/pages/index.vue?macro=true";
import { default as details8ORTIDqErRMeta } from "/code/pages/inventory/[id]/details.vue?macro=true";
import { default as indexNael6OdiLTMeta } from "/code/pages/inventory/create/index.vue?macro=true";
import { default as indexpIet7tIxptMeta } from "/code/pages/inventory/index.vue?macro=true";
import { default as indexWYtQZIU9DdMeta } from "/code/pages/orders/index.vue?macro=true";
import { default as policyhHqv0AjGiuMeta } from "/code/pages/policy.vue?macro=true";
import { default as map0vthikIkNLMeta } from "/code/pages/settings/branch-offices/[id]/map.vue?macro=true";
import { default as pickup_45time3osvuhqh9mMeta } from "/code/pages/settings/branch-offices/[id]/pickup-time.vue?macro=true";
import { default as indexovXIDqtTx3Meta } from "/code/pages/settings/branch-offices/index.vue?macro=true";
import { default as newGBh7FJzilVMeta } from "/code/pages/settings/branch-offices/new.vue?macro=true";
import { default as companyqTYG5PtDy9Meta } from "/code/pages/settings/company.vue?macro=true";
import { default as indexx5lWLRu7M6Meta } from "/code/pages/settings/index.vue?macro=true";
import { default as securitydX5eyqfOJwMeta } from "/code/pages/settings/security.vue?macro=true";
import { default as teamegKoE9b7E4Meta } from "/code/pages/settings/team.vue?macro=true";
import { default as support00HN4fIoRzMeta } from "/code/pages/support.vue?macro=true";
import { default as terms_45of_45use3k3kXO1aw0Meta } from "/code/pages/terms-of-use.vue?macro=true";
export default [
  {
    name: "auth-create-account",
    path: "/auth/create-account",
    meta: create_45accountVBTzotrxSxMeta || {},
    component: () => import("/code/pages/auth/create-account.vue").then(m => m.default || m)
  },
  {
    name: "auth-email-confirmation-success",
    path: "/auth/email-confirmation-success",
    meta: email_45confirmation_45successHt59hw76MWMeta || {},
    component: () => import("/code/pages/auth/email-confirmation-success.vue").then(m => m.default || m)
  },
  {
    name: "auth-password-confirm-uid-token",
    path: "/auth/password-confirm/:uid()/:token()",
    meta: _91token_93UigJ9VlsKBMeta || {},
    component: () => import("/code/pages/auth/password-confirm/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-password-reset",
    path: "/auth/password-reset",
    meta: password_45resetG8ND7qxmiNMeta || {},
    component: () => import("/code/pages/auth/password-reset.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/code/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexKRDw4UYEiPMeta || {},
    component: () => import("/code/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inventory-id-details",
    path: "/inventory/:id()/details",
    component: () => import("/code/pages/inventory/[id]/details.vue").then(m => m.default || m)
  },
  {
    name: "inventory-create",
    path: "/inventory/create",
    component: () => import("/code/pages/inventory/create/index.vue").then(m => m.default || m)
  },
  {
    name: "inventory",
    path: "/inventory",
    component: () => import("/code/pages/inventory/index.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/code/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "policy",
    path: "/policy",
    meta: policyhHqv0AjGiuMeta || {},
    component: () => import("/code/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: "settings-branch-offices-id-map",
    path: "/settings/branch-offices/:id()/map",
    component: () => import("/code/pages/settings/branch-offices/[id]/map.vue").then(m => m.default || m)
  },
  {
    name: "settings-branch-offices-id-pickup-time",
    path: "/settings/branch-offices/:id()/pickup-time",
    component: () => import("/code/pages/settings/branch-offices/[id]/pickup-time.vue").then(m => m.default || m)
  },
  {
    name: "settings-branch-offices",
    path: "/settings/branch-offices",
    component: () => import("/code/pages/settings/branch-offices/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-branch-offices-new",
    path: "/settings/branch-offices/new",
    component: () => import("/code/pages/settings/branch-offices/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-company",
    path: "/settings/company",
    component: () => import("/code/pages/settings/company.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/code/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-security",
    path: "/settings/security",
    component: () => import("/code/pages/settings/security.vue").then(m => m.default || m)
  },
  {
    name: "settings-team",
    path: "/settings/team",
    component: () => import("/code/pages/settings/team.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    meta: support00HN4fIoRzMeta || {},
    component: () => import("/code/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    meta: terms_45of_45use3k3kXO1aw0Meta || {},
    component: () => import("/code/pages/terms-of-use.vue").then(m => m.default || m)
  }
]