import { defineStore } from 'pinia'
import type { UserInfo } from '~/client_api'

export interface userState {
  user: null | UserInfo
  isLoggedIn: boolean | null
}

export const useUserStore = defineStore('user', {
  state: (): userState => ({
    isLoggedIn: null,
    user: null,
  }),

  actions: {
    setUser(user: UserInfo) {
      this.user = user
    },
    setIsLoggedIn(value: boolean) {
      this.isLoggedIn = value
    },
  },
})
